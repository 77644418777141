import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Contacts = () => {
  const navigate = useNavigate();

  const onFrameContainer29Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderLinkContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="relative bg-background w-full h-[790px] overflow-hidden text-left text-base text-gray font-fira-code">
      <div className="absolute top-[256px] left-[-77px] w-[1530px] h-[1237px]">
        <div className="absolute top-[1134px] left-[1363px] w-[103px] h-[103px] flex flex-col items-start justify-between">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
        </div>
        <div className="absolute top-[511px] left-[1390px] w-[103px] h-[103px] flex flex-col items-start justify-between">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
        </div>
        <div className="absolute top-[145px] left-[46px] w-[109px] h-[49px] flex flex-col items-start justify-between">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch hidden flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
        </div>
        <div className="absolute top-[965px] left-[0px] box-border w-[155px] h-[155px] border-[1px] border-solid border-gray" />
        <div className="absolute top-[0px] left-[1375px] box-border w-[155px] h-[155px] border-[1px] border-solid border-gray" />
      </div>
      <div className="absolute bottom-[0px] left-[calc(50%_-_683px)] flex flex-col pt-0 px-0 pb-8 items-center justify-start gap-[32px] text-white">
        <div className="relative box-border w-[1367px] h-px border-t-[1px] border-solid border-gray" />
        <div className="flex flex-col items-center justify-start gap-[48px]">
          <div className="w-[1025px] flex flex-row items-start justify-between">
            <div className="flex flex-col items-start justify-start gap-[16px]">
              <div className="self-stretch flex flex-row items-center justify-start gap-[24px]">
                <div className="flex flex-row items-center justify-start gap-[9px]">
                  <div className="relative w-4 h-4">
                    <div className="relative bg-white w-4 h-4">
                      <div className="absolute h-1/4 w-3/12 top-[0%] right-[25%] bottom-[75%] left-[50%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[50%] right-[0%] bottom-[25%] left-[75%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[25%] right-[0%] bottom-[50%] left-[75%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[0%] right-[0%] bottom-[75%] left-[75%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[50%] right-[25%] bottom-[25%] left-[50%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[25%] right-[50%] bottom-[50%] left-[25%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[25%] right-[75%] bottom-[50%] left-[0%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[50%] right-[75%] bottom-[25%] left-[0%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[75%] right-[75%] bottom-[0%] left-[0%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[75%] right-[50%] bottom-[0%] left-[25%] bg-white" />
                    </div>
                  </div>
                  <div className="relative font-medium">Elias</div>
                </div>
                <a
                  className="relative text-gray [text-decoration:none]"
                  href="mailto:elias@elias-dev.ml"
                  target="_blank"
                >
                  elias@elias-dev.ml
                </a>
              </div>
              <div className="relative">
                Web designer and front-end developer
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[12px] text-5xl">
              <div className="relative font-medium">Media</div>
              <img className="relative w-28 h-8" alt="" src="/group-221.svg" />
            </div>
          </div>
          <div className="relative text-gray inline-block w-[298px]">
            © Copyright 2022. Made by Elias
          </div>
        </div>
      </div>
      <div className="absolute top-[114px] left-[171px] w-[174px] h-[77px] flex flex-col items-start justify-start gap-[14px] text-13xl text-primary">
        <div className="flex flex-row items-start justify-start">
          <div className="relative font-semibold">/</div>
          <div className="relative font-semibold text-white">contacts</div>
        </div>
        <div className="relative text-base text-white">Who am i?</div>
      </div>
      <div className="absolute top-[403px] left-[171px] flex flex-row items-start justify-start text-13xl text-primary">
        <div className="relative font-medium">#</div>
        <div className="relative font-medium text-white">all-media</div>
      </div>
      <div className="absolute top-[467px] left-[171px] flex flex-row items-center justify-start gap-[5px]">
        <img className="relative w-8 h-8" alt="" src="/twitter.svg" />
        <div className="relative">@elias</div>
      </div>
      <div className="absolute top-[466px] left-[288px] flex flex-row items-center justify-start gap-[5px]">
        <img className="relative w-8 h-8" alt="" src="/twitter.svg" />
        <div className="relative">@elias</div>
      </div>
      <div className="absolute top-[237px] left-[171px] w-[1061px] h-[141px] flex flex-row items-start justify-start gap-[117px]">
        <div className="relative leading-[26px] font-medium inline-block w-[505px] shrink-0">
          I’m interested in freelance opportunities. However, if you have other
          request or question, don’t hesitate to contact me
        </div>
        <div className="w-[439px] h-[141px] flex flex-row items-start justify-start gap-[11px] text-white">
          <div className="flex flex-col p-4 items-start justify-start gap-[8px] border-[1px] border-solid border-gray">
            <div className="relative font-medium">Support me here</div>
            <div className="relative text-gray">4149500120690030</div>
          </div>
          <div className="flex flex-col p-4 items-start justify-start gap-[16px] border-[1px] border-solid border-gray">
            <div className="relative font-semibold">Message me here</div>
            <div className="flex flex-col items-start justify-start gap-[8px] text-gray">
              <div className="flex flex-row items-center justify-start gap-[5px]">
                <img className="relative w-8 h-8" alt="" src="/discord.svg" />
                <div className="relative">Elias#1234</div>
              </div>
              <div className="flex flex-row items-center justify-start gap-[5px]">
                <img className="relative w-8 h-8" alt="" src="/email.svg" />
                <div className="relative">elias@elias-dev.ml</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[0px] left-[171px] bg-background w-[1024px] flex flex-row pt-8 px-0 pb-2 box-border items-end justify-between text-white">
        <div
          className="flex flex-row items-center justify-start gap-[8px] cursor-pointer"
          onClick={onFrameContainer29Click}
        >
          <div className="relative w-4 h-4">
            <div className="relative bg-white w-4 h-4">
              <div className="absolute h-1/4 w-3/12 top-[0%] right-[25%] bottom-[75%] left-[50%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[50%] right-[0%] bottom-[25%] left-[75%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[25%] right-[0%] bottom-[50%] left-[75%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[0%] right-[0%] bottom-[75%] left-[75%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[50%] right-[25%] bottom-[25%] left-[50%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[25%] right-[50%] bottom-[50%] left-[25%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[25%] right-[75%] bottom-[50%] left-[0%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[50%] right-[75%] bottom-[25%] left-[0%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[75%] right-[75%] bottom-[0%] left-[0%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[75%] right-[50%] bottom-[0%] left-[25%] bg-white" />
            </div>
          </div>
          <b className="relative">Elias</b>
        </div>
        <div className="flex flex-row items-start justify-start gap-[32px] text-primary">
          <div
            className="flex flex-row items-start justify-start cursor-pointer"
            onClick={onHeaderLinkContainerClick}
          >
            <div className="relative font-medium">#</div>
            <div className="relative font-medium text-white">home</div>
          </div>
          <div className="flex flex-row items-start justify-start">
            <div className="relative">#</div>
            <div className="relative text-gray">works</div>
          </div>
          <div className="flex flex-row items-start justify-start">
            <div className="relative">#</div>
            <div className="relative text-gray">about-me</div>
          </div>
          <div className="flex flex-row items-start justify-start">
            <div className="relative">#</div>
            <div className="relative text-gray">contacts</div>
          </div>
          <div className="flex flex-row items-center justify-start relative gap-[4px] text-gray">
            <div className="relative font-semibold z-[0]">EN</div>
            <img
              className="relative w-2.5 h-[6.41px] z-[1]"
              alt=""
              src="/group-58.svg"
            />
            <div className="my-0 mx-[!important] absolute top-[25px] left-[0px] bg-background box-border h-px overflow-hidden flex flex-col p-2 items-start justify-start gap-[8px] opacity-[0] z-[2] border-[1px] border-solid border-gray">
              <div className="relative">RU</div>
              <div className="relative">UA</div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[0px] left-[17px] bg-background flex flex-col items-center justify-start gap-[8px]">
        <img
          className="relative w-px h-[191px]"
          alt=""
          src="/line-10-stroke1.svg"
        />
        <div className="flex flex-col items-center justify-start gap-[8px]">
          <img className="relative w-8 h-8" alt="" src="/github.svg" />
          <img className="relative w-8 h-8" alt="" src="/dribble.svg" />
          <img className="relative w-8 h-8" alt="" src="/figma.svg" />
        </div>
      </div>
    </div>
  );
};

export default Contacts;
