import SectionHeader from "./SectionHeader";

const SkillSection = () => {
    const data = [
        {
            category: 'Languages',
            skills: ['JavaScript', 'TypeScript', 'HTML', 'CSS', 'Ruby']
        },
        {
            category: 'Databases',
            skills: ['MongoDB', 'MySQL', 'PostgreSQL', 'Firebase']
        },
        {
            category: 'Frameworks',
            skills: ['React', 'Next.js', 'Node.js', 'Express.js', 'Rails', 'Bootstrap', 'Tailwind CSS', 'Material UI', 'Chakra UI', 'Jest', 'React Testing Library']
        },
        {
            category: 'Tools',
            skills: ['Git', 'Zsh', 'VSCode', 'IntelliJ', 'Azure Devops', 'Jenkins', 'Docker', 'Kubernetes', 'GCP']
        }
    ];

    return (
        <div className="mb-40">
            <SectionHeader title="skills" />
            <div className="flex w-full">
                <img className="hidden h-full" src="frameworks.jpg" alt="Hero Image" width='50px' />
                <div className="text-base text-white gap-[10px] w-full">
                    {data.map((item) => {
                        return (
                            <div className="flex flex-col py-2 px-0 mb-5 gap-[8px] border-[1px] border-solid border-gray h-fit">
                                <div className="self-stretch text-primary py-0 px-2 pb-2 border-b-[1px] border-solid border-gray">{item.category}</div>
                                <div className="sm:columns-2 gap-[8px] text-gray pl-2">
                                    {item.skills.map((skill) => {
                                        return (
                                            <div className="relative text-white sm:basis-1/2">{skill}</div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default SkillSection;