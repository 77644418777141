import {
  Routes,
  Route
} from "react-router-dom";
import Home from "./pages/home";
import Projects from "./pages/projects";
import Burger from "./pages/burger";
import Contacts from "./pages/contacts";
import About from "./pages/about";
import Support from "./pages/support";
import TopNav from "./components/TopNav";
import Footer from "./components/Footer";
import SideFloat from "./components/SideFloat";

function App() {
  return (
    <div className="bg-background text-left text-base text-white font-fira-code scroll-smooth">
      <TopNav />
      <div className='max-w-5xl mx-auto px-4 pb-1'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          {/* <Route path="/burger" element={<Burger />} /> */}
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/about" element={<About />} />
          <Route path="/support" element={<Support />} />
        </Routes>
      </div>
      {/* <SideFloat /> */}
      <Footer />
    </div>
  );
}

export default App;
