import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Projects = () => {
  const navigate = useNavigate();

  const onFrameContainer110Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderLinkContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="relative bg-background w-full h-[2482px] overflow-hidden text-left text-13xl text-primary font-fira-code">
      <div className="absolute top-[256px] left-[-77px] w-[1530px] h-[1648px]">
        <div className="absolute top-[1134px] left-[1363px] w-[103px] h-[103px] flex flex-col items-start justify-between">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
        </div>
        <div className="absolute top-[1545px] left-[26px] w-[103px] h-[103px] flex flex-col items-start justify-between">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
        </div>
        <div className="absolute top-[145px] left-[46px] w-[109px] h-[49px] flex flex-col items-start justify-between">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch hidden flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
        </div>
        <div className="absolute top-[965px] left-[0px] box-border w-[155px] h-[155px] border-[1px] border-solid border-gray" />
        <div className="absolute top-[0px] left-[1375px] box-border w-[155px] h-[155px] border-[1px] border-solid border-gray" />
      </div>
      <div className="absolute bottom-[0px] left-[calc(50%_-_683px)] w-[1366px] flex flex-col pt-0 px-0 pb-8 box-border items-center justify-start gap-[32px] text-base text-white">
        <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gray" />
        <div className="self-stretch flex flex-col items-center justify-start gap-[48px]">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="flex flex-col items-start justify-start gap-[16px]">
              <div className="self-stretch flex flex-row items-center justify-start gap-[24px]">
                <div className="flex flex-row items-center justify-start gap-[9px]">
                  <div className="relative w-4 h-4">
                    <div className="relative bg-white w-4 h-4">
                      <div className="absolute h-1/4 w-3/12 top-[0%] right-[25%] bottom-[75%] left-[50%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[50%] right-[0%] bottom-[25%] left-[75%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[25%] right-[0%] bottom-[50%] left-[75%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[0%] right-[0%] bottom-[75%] left-[75%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[50%] right-[25%] bottom-[25%] left-[50%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[25%] right-[50%] bottom-[50%] left-[25%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[25%] right-[75%] bottom-[50%] left-[0%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[50%] right-[75%] bottom-[25%] left-[0%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[75%] right-[75%] bottom-[0%] left-[0%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[75%] right-[50%] bottom-[0%] left-[25%] bg-white" />
                    </div>
                  </div>
                  <div className="relative font-medium">Elias</div>
                </div>
                <a
                  className="relative text-gray [text-decoration:none]"
                  href="mailto:elias@elias-dev.ml"
                  target="_blank"
                >
                  elias@elias-dev.ml
                </a>
              </div>
              <div className="relative">
                Web designer and front-end developer
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[12px] text-5xl">
              <div className="relative font-medium">Media</div>
              <img className="relative w-28 h-8" alt="" src="/group-22.svg" />
            </div>
          </div>
          <div className="relative text-gray inline-block w-[298px]">
            © Copyright 2022. Made by Elias
          </div>
        </div>
      </div>
      <div className="absolute top-[114px] left-[171px] w-[183px] h-[77px] flex flex-col items-center justify-start gap-[14px]">
        <div className="flex flex-row items-start justify-start">
          <div className="relative font-semibold">/</div>
          <div className="relative font-semibold text-white">projects</div>
        </div>
        <div className="relative text-base text-white">List of my projects</div>
      </div>
      <div className="absolute top-[259px] left-[171px] w-[1023.74px] flex flex-col items-start justify-start gap-[48px]">
        <div className="self-stretch flex flex-row items-start justify-start">
          <div className="flex-1 relative font-medium">#</div>
          <div className="flex-1 relative font-medium text-white">
            complete-apps
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-base text-gray">
          <div className="self-stretch flex flex-row items-start justify-start gap-[16px]">
            <div className="flex flex-col items-start justify-start border-[1px] border-solid border-gray">
              <img
                className="relative w-[330.58px] h-[202px] object-cover"
                alt=""
                src="/rectangle-223@2x.png"
              />
              <div className="self-stretch flex flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">SCSS</div>
                <div className="relative">Python</div>
                <div className="relative">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch hidden flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">SCSS</div>
                <div className="relative">Python</div>
                <div className="relative">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch flex flex-col p-4 items-start justify-start gap-[16px] text-white border-t-[1px] border-solid border-gray">
                <div className="self-stretch relative text-5xl font-medium">
                  ChertNodes
                </div>
                <div className="self-stretch relative text-gray">{`Minecraft servers hosting `}</div>
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex flex-row py-2 px-4 items-start justify-start border-[1px] border-solid border-primary">
                    <div className="relative font-medium">{`Live <~>`}</div>
                  </div>
                  <div className="flex flex-row py-2 px-4 items-start justify-start text-gray border-[1px] border-solid border-gray">
                    <div className="relative font-medium">{`Cached >=`}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start border-[1px] border-solid border-gray">
              <img
                className="relative w-[330.58px] h-[202px] object-cover"
                alt=""
                src="/rectangle-224@2x.png"
              />
              <div className="self-stretch flex flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">CSS</div>
                <div className="relative">Express</div>
                <div className="relative">Node.js</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch hidden flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">SCSS</div>
                <div className="relative">Python</div>
                <div className="relative">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch flex flex-col p-4 items-start justify-start gap-[16px] text-white border-t-[1px] border-solid border-gray">
                <div className="self-stretch relative text-5xl font-medium">
                  Kahoot Answers Viewer
                </div>
                <div className="self-stretch relative text-gray">
                  Get answers to your kahoot quiz
                </div>
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex flex-row py-2 px-4 items-start justify-start border-[1px] border-solid border-primary">
                    <div className="relative font-medium">{`Live <~>`}</div>
                  </div>
                  <div className="hidden flex-row py-2 px-4 items-start justify-start text-gray border-[1px] border-solid border-gray">
                    <div className="relative font-medium">{`Cached >=`}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start border-[1px] border-solid border-gray">
              <img
                className="relative w-[330.58px] h-[202px] object-cover"
                alt=""
                src="/rectangle-225@2x.png"
              />
              <div className="self-stretch flex flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">React</div>
                <div className="relative">Express</div>
                <div className="relative">Discord.js</div>
                <div className="relative">Node.js</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch hidden flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">SCSS</div>
                <div className="relative">Python</div>
                <div className="relative">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch flex flex-col p-4 items-start justify-start gap-[16px] text-white border-t-[1px] border-solid border-gray">
                <div className="self-stretch relative text-5xl font-medium">
                  ProtectX
                </div>
                <div className="self-stretch relative text-gray">
                  Discord anti-crash bot
                </div>
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <div className="hidden flex-row py-2 px-4 items-start justify-start border-[1px] border-solid border-primary">
                    <div className="relative font-medium">{`Live <~>`}</div>
                  </div>
                  <div className="flex flex-row py-2 px-4 items-start justify-start text-gray border-[1px] border-solid border-gray">
                    <div className="relative font-medium">{`Cached >=`}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start gap-[16px]">
            <div className="flex flex-col items-start justify-start border-[1px] border-solid border-gray">
              <img
                className="relative w-[330.58px] h-[202px] object-cover"
                alt=""
                src="/rectangle-226@2x.png"
              />
              <div className="self-stretch flex flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">CSS</div>
                <div className="relative">JS</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch hidden flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">SCSS</div>
                <div className="relative">Python</div>
                <div className="relative">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch flex flex-col p-4 items-start justify-start gap-[16px] text-white border-t-[1px] border-solid border-gray">
                <div className="self-stretch relative text-5xl font-medium">
                  Kotik Bot
                </div>
                <div className="self-stretch relative text-gray">
                  Multi-functional discord bot
                </div>
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex flex-row py-2 px-4 items-start justify-start border-[1px] border-solid border-primary">
                    <div className="relative font-medium">{`Live <~>`}</div>
                  </div>
                  <div className="hidden flex-row py-2 px-4 items-start justify-start text-gray border-[1px] border-solid border-gray">
                    <div className="relative font-medium">{`Cached >=`}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-border w-[334px] flex flex-col items-start justify-start border-[1px] border-solid border-gray">
              <img
                className="relative w-[330.58px] h-[202px] object-cover"
                alt=""
                src="/rectangle-227@2x.png"
              />
              <div className="self-stretch flex flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">Vue</div>
                <div className="relative">TS</div>
                <div className="relative">Less</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch hidden flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">SCSS</div>
                <div className="relative">Python</div>
                <div className="relative">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch flex flex-col p-4 items-start justify-start gap-[16px] text-white border-t-[1px] border-solid border-gray">
                <div className="self-stretch relative text-5xl font-medium">
                  Portfolio
                </div>
                <div className="self-stretch relative text-gray">
                  You’re using it rn
                </div>
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex flex-row py-2 px-4 items-start justify-start border-[1px] border-solid border-primary">
                    <div className="relative font-medium">{`Github <~>`}</div>
                  </div>
                  <div className="hidden flex-row py-2 px-4 items-start justify-start text-gray border-[1px] border-solid border-gray">
                    <div className="relative font-medium">{`Cached >=`}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[1259px] left-[171px] w-[1025px] h-[828px] flex flex-col items-start justify-start gap-[48px]">
        <div className="flex flex-row items-start justify-start">
          <div className="relative font-medium">#</div>
          <div className="relative font-medium text-white">small-projects</div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[16px] text-base text-gray">
          <div className="flex flex-row items-start justify-start gap-[16px]">
            <div className="box-border w-[333px] flex flex-col items-start justify-start border-[1px] border-solid border-gray">
              <img
                className="relative w-[330.58px] h-[201px] object-cover hidden"
                alt=""
                src="/rectangle-226@2x.png"
              />
              <div className="self-stretch flex flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">Discord.js</div>
                <div className="relative">TS</div>
                <div className="relative">JS</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch hidden flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">SCSS</div>
                <div className="relative">Python</div>
                <div className="relative">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch flex flex-col p-4 items-start justify-start gap-[16px] text-white border-t-[1px] border-solid border-gray">
                <div className="self-stretch relative text-5xl font-medium">
                  Bot boilerplate
                </div>
                <div className="self-stretch relative text-gray">
                  Start creating scalable discord.js bot with typescript in
                  seconds
                </div>
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex flex-row py-2 px-4 items-start justify-start border-[1px] border-solid border-primary">
                    <div className="relative font-medium">{`Github <~>`}</div>
                  </div>
                  <div className="hidden flex-row py-2 px-4 items-start justify-start text-gray border-[1px] border-solid border-gray">
                    <div className="relative font-medium">{`Cached >=`}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-border w-[333px] flex flex-col items-start justify-start border-[1px] border-solid border-gray">
              <img
                className="relative w-[330.58px] h-[201px] object-cover hidden"
                alt=""
                src="/rectangle-226@2x.png"
              />
              <div className="self-stretch flex flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">VUE</div>
                <div className="relative">CSS</div>
                <div className="relative">JS</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch hidden flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">SCSS</div>
                <div className="relative">Python</div>
                <div className="relative">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch flex flex-col p-4 items-start justify-start gap-[16px] text-white border-t-[1px] border-solid border-gray">
                <div className="self-stretch relative text-5xl font-medium">
                  My blog
                </div>
                <div className="self-stretch relative text-gray">
                  Front-end of my future blog website written in vue
                </div>
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex flex-row py-2 px-4 items-start justify-start border-[1px] border-solid border-primary">
                    <div className="relative font-medium">{`Github <~>`}</div>
                  </div>
                  <div className="hidden flex-row py-2 px-4 items-start justify-start text-gray border-[1px] border-solid border-gray">
                    <div className="relative font-medium">{`Cached >=`}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-border w-[333px] flex flex-col items-start justify-start border-[1px] border-solid border-gray">
              <img
                className="relative w-[330.58px] h-[201px] object-cover hidden"
                alt=""
                src="/rectangle-226@2x.png"
              />
              <div className="self-stretch flex flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">Figma</div>
                <div className="relative hidden">CSS</div>
                <div className="relative hidden">JS</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch hidden flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">SCSS</div>
                <div className="relative">Python</div>
                <div className="relative">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch flex flex-col p-4 items-start justify-start gap-[16px] text-white border-t-[1px] border-solid border-gray">
                <div className="self-stretch relative text-5xl font-medium">
                  Chess pro
                </div>
                <div className="self-stretch relative text-gray">
                  Figma landing page about service for viewing chess tournaments
                </div>
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex flex-row py-2 px-4 items-start justify-start border-[1px] border-solid border-primary">
                    <div className="relative font-medium">{`Figma <~>`}</div>
                  </div>
                  <div className="hidden flex-row py-2 px-4 items-start justify-start text-gray border-[1px] border-solid border-gray">
                    <div className="relative font-medium">{`Cached >=`}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start gap-[16px]">
            <div className="box-border w-[333px] flex flex-col items-start justify-start border-[1px] border-solid border-gray">
              <img
                className="relative w-[330.58px] h-[201px] object-cover hidden"
                alt=""
                src="/rectangle-226@2x.png"
              />
              <div className="self-stretch flex flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">Figma</div>
                <div className="relative hidden">TS</div>
                <div className="relative hidden">JS</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch hidden flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">SCSS</div>
                <div className="relative">Python</div>
                <div className="relative">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch flex flex-col p-4 items-start justify-start gap-[16px] text-white border-t-[1px] border-solid border-gray">
                <div className="self-stretch relative text-5xl font-medium">
                  Crash protect website
                </div>
                <div className="self-stretch relative text-gray">
                  Figma template for website about anti-raid, anti-crash discord
                  bot
                </div>
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex flex-row py-2 px-4 items-start justify-start border-[1px] border-solid border-primary">
                    <div className="relative font-medium">{`Figma <~>`}</div>
                  </div>
                  <div className="hidden flex-row py-2 px-4 items-start justify-start text-gray border-[1px] border-solid border-gray">
                    <div className="relative font-medium">{`Cached >=`}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-border w-[333px] flex flex-col items-start justify-start border-[1px] border-solid border-gray">
              <img
                className="relative w-[330.58px] h-[201px] object-cover hidden"
                alt=""
                src="/rectangle-226@2x.png"
              />
              <div className="self-stretch flex flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">CSS</div>
                <div className="relative hidden">JS</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch hidden flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">SCSS</div>
                <div className="relative">Python</div>
                <div className="relative">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch flex flex-col p-4 items-start justify-start gap-[16px] text-white border-t-[1px] border-solid border-gray">
                <div className="self-stretch relative text-5xl font-medium">
                  CSS expirements
                </div>
                <div className="self-stretch relative text-gray">
                  Collection of my different little projects in css
                </div>
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex flex-row py-2 px-4 items-start justify-start border-[1px] border-solid border-primary">
                    <div className="relative font-medium">{`Live <~>`}</div>
                  </div>
                  <div className="hidden flex-row py-2 px-4 items-start justify-start text-gray border-[1px] border-solid border-gray">
                    <div className="relative font-medium">{`Cached >=`}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-border w-[333px] flex flex-col items-start justify-start border-[1px] border-solid border-gray">
              <img
                className="relative w-[330.58px] h-[201px] object-cover hidden"
                alt=""
                src="/rectangle-226@2x.png"
              />
              <div className="self-stretch flex flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">Lua</div>
                <div className="relative">NeoVim</div>
                <div className="relative hidden">JS</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch hidden flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">SCSS</div>
                <div className="relative">Python</div>
                <div className="relative">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch flex flex-col p-4 items-start justify-start gap-[16px] text-white border-t-[1px] border-solid border-gray">
                <div className="self-stretch relative text-5xl font-medium">
                  Web Dev nvim config
                </div>
                <div className="self-stretch relative text-gray">
                  Config for neovim perfect for web developer
                </div>
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex flex-row py-2 px-4 items-start justify-start border-[1px] border-solid border-primary">
                    <div className="relative font-medium">{`Github <~>`}</div>
                  </div>
                  <div className="hidden flex-row py-2 px-4 items-start justify-start text-gray border-[1px] border-solid border-gray">
                    <div className="relative font-medium">{`Cached >=`}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start gap-[16px]">
            <div className="box-border w-[333px] flex flex-col items-start justify-start border-[1px] border-solid border-gray">
              <img
                className="relative w-[330.58px] h-[201px] object-cover hidden"
                alt=""
                src="/rectangle-226@2x.png"
              />
              <div className="self-stretch flex flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">Python</div>
                <div className="relative">Quart</div>
                <div className="relative">HTML</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch hidden flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">SCSS</div>
                <div className="relative">Python</div>
                <div className="relative">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch flex flex-col p-4 items-start justify-start gap-[16px] text-white border-t-[1px] border-solid border-gray">
                <div className="self-stretch relative text-5xl font-medium">
                  Ooku
                </div>
                <div className="self-stretch relative text-gray">
                  Simple link shortener with auth
                </div>
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex flex-row py-2 px-4 items-start justify-start border-[1px] border-solid border-primary">
                    <div className="relative font-medium">{`Live <~>`}</div>
                  </div>
                  <div className="hidden flex-row py-2 px-4 items-start justify-start text-gray border-[1px] border-solid border-gray">
                    <div className="relative font-medium">{`Cached >=`}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-border w-[333px] flex flex-col items-start justify-start border-[1px] border-solid border-gray">
              <img
                className="relative w-[330.58px] h-[201px] object-cover hidden"
                alt=""
                src="/rectangle-226@2x.png"
              />
              <div className="self-stretch flex flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">Figma</div>
                <div className="relative hidden">Quart</div>
                <div className="relative hidden">HTML</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch hidden flex-row p-2 items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">SCSS</div>
                <div className="relative">Python</div>
                <div className="relative">Flask</div>
                <div className="relative hidden">Flask</div>
                <div className="relative hidden">Flask</div>
              </div>
              <div className="self-stretch flex flex-col p-4 items-start justify-start gap-[16px] text-white border-t-[1px] border-solid border-gray">
                <div className="self-stretch relative text-5xl font-medium">
                  School website
                </div>
                <div className="self-stretch relative text-gray">
                  Figma template website for my school
                </div>
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex flex-row py-2 px-4 items-start justify-start border-[1px] border-solid border-primary">
                    <div className="relative font-medium">{`Figma <~>`}</div>
                  </div>
                  <div className="hidden flex-row py-2 px-4 items-start justify-start text-gray border-[1px] border-solid border-gray">
                    <div className="relative font-medium">{`Cached >=`}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[0px] left-[171px] bg-background w-[1024px] flex flex-row pt-8 px-0 pb-2 box-border items-end justify-between text-base text-white">
        <div
          className="flex flex-row items-center justify-start gap-[8px] cursor-pointer"
          onClick={onFrameContainer110Click}
        >
          <div className="relative w-4 h-4">
            <div className="relative bg-white w-4 h-4">
              <div className="absolute h-1/4 w-3/12 top-[0%] right-[25%] bottom-[75%] left-[50%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[50%] right-[0%] bottom-[25%] left-[75%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[25%] right-[0%] bottom-[50%] left-[75%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[0%] right-[0%] bottom-[75%] left-[75%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[50%] right-[25%] bottom-[25%] left-[50%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[25%] right-[50%] bottom-[50%] left-[25%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[25%] right-[75%] bottom-[50%] left-[0%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[50%] right-[75%] bottom-[25%] left-[0%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[75%] right-[75%] bottom-[0%] left-[0%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[75%] right-[50%] bottom-[0%] left-[25%] bg-white" />
            </div>
          </div>
          <b className="relative">Elias</b>
        </div>
        <div className="flex flex-row items-start justify-start gap-[32px] text-primary">
          <div
            className="flex flex-row items-start justify-start cursor-pointer"
            onClick={onHeaderLinkContainerClick}
          >
            <div className="relative font-medium">#</div>
            <div className="relative font-medium text-white">home</div>
          </div>
          <div className="flex flex-row items-start justify-start">
            <div className="relative">#</div>
            <div className="relative text-gray">works</div>
          </div>
          <div className="flex flex-row items-start justify-start">
            <div className="relative">#</div>
            <div className="relative text-gray">about-me</div>
          </div>
          <div className="flex flex-row items-start justify-start">
            <div className="relative">#</div>
            <div className="relative text-gray">contacts</div>
          </div>
          <div className="flex flex-row items-center justify-start relative gap-[4px] text-gray">
            <div className="relative font-semibold z-[0]">EN</div>
            <img
              className="relative w-2.5 h-[6.41px] z-[1]"
              alt=""
              src="/group-58.svg"
            />
            <div className="my-0 mx-[!important] absolute top-[25px] left-[0px] bg-background box-border h-px overflow-hidden flex flex-col p-2 items-start justify-start gap-[8px] opacity-[0] z-[2] border-[1px] border-solid border-gray">
              <div className="relative">RU</div>
              <div className="relative">UA</div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[0px] left-[17px] bg-background flex flex-col items-center justify-start gap-[8px]">
        <img
          className="relative w-px h-[191px]"
          alt=""
          src="/line-10-stroke1.svg"
        />
        <div className="flex flex-col items-center justify-start gap-[8px]">
          <img className="relative w-8 h-8" alt="" src="/github.svg" />
          <img className="relative w-8 h-8" alt="" src="/dribble.svg" />
          <img className="relative w-8 h-8" alt="" src="/figma.svg" />
        </div>
      </div>
    </div>
  );
};

export default Projects;
