const ProjectCard = ({cardData}) => {
    return (
        <div className="flex flex-col sm:flex-row justify-start border-[1px] border-solid border-gray max-w-full">
            <div className="sm:basis-1/2">
              <img
                className="w-full h-full relative object-cover"
                alt=""
                src={cardData.thumbnail}
              />
            </div>
            <div className="w-full sm:basis-1/2">
              <div className="flex flex-row p-2 items-start justify-start gap-[8px]">
                  {cardData.stack.map(stack => <div className="relative">{stack}</div>)}
              </div>
              <div className="self-stretch flex flex-col p-4 items-start justify-start gap-[16px] text-white border-t-[1px] border-solid border-gray">
                <div className="self-stretch relative text-5xl font-medium">
                  {cardData.title}
                </div>
                <div className="self-stretch relative text-gray">{cardData.briefDescription}</div>
                <div className="flex flex-row items-start justify-start gap-[16px]">
                  <div className="flex flex-row py-2 px-4 items-start justify-start border-[1px] border-solid border-primary">
                    <div className="relative font-medium">{`Live <~>`}</div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    );
 };

 export default ProjectCard;