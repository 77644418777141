const Support = () => {
  return (
    <div className="relative bg-background box-border w-full h-[145px] flex flex-col p-4 items-start justify-start gap-[16px] text-left text-base text-white font-fira-code border-[1px] border-solid border-gray">
      <div className="relative font-medium">Support me here</div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-gray">
        <div className="self-stretch flex flex-row items-center justify-start gap-[5px] sm:flex-row sm:gap-[5px] sm:items-center sm:justify-start">
          <img className="relative w-8 h-8" alt="" src="/card.svg" />
          <div className="relative">4149500120690030</div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-start gap-[5px]">
          <img className="relative w-8 h-8" alt="" src="/bitcoin.svg" />
          <div className="relative">3E8ociqZa9mZUSwGdSmAEMAoAxBK3FNDcd</div>
        </div>
      </div>
    </div>
  );
};

export default Support;
