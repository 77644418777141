const ContactForm = () => {
    return (
        <form className="flex flex-col w-full mb-4">
            <div className="flex flex-row gap-[16px] justify-between">
                <input className="form-input p-2 mb-4 basis-1/2" type="text" placeholder="First Name" />
                <input className="form-input  p-2 mb-4 basis-1/2" type="text" placeholder="Last Name" />
            </div>
            <input className="form-input  p-2 mb-4" type="email" placeholder="Email" />
            <input className="form-input  p-2 mb-4" type="text" placeholder="Organization" />
            <input className="form-input  p-2 mb-4" type="phone" placeholder="Phone" />
            <textarea className="form-input  p-2 mb-4" type="text" placeholder="Message" />
            <a className="no-underline text-primary px-2 py-2 border-[1px] border-solid border-primary font-medium text-center">Submit</a>
        </form>
    )
};

export default ContactForm;