import ContactDetails from "./ContactDetails";

const TopNav = () => { 
    return (
        <div className="fixed box-border bg-white w-full text-background z-10 p-2">
            <ContactDetails isNavDisplay={true} />
        </div>
    )
};

export default TopNav;