import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  const onFrameContainer92Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderLinkContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="relative bg-background w-full h-[1787px] overflow-hidden text-left text-base text-primary font-fira-code">
      <div className="absolute top-[256px] left-[-77px] w-[1530px] h-[1237px]">
        <div className="absolute top-[1134px] left-[1363px] w-[103px] h-[103px] flex flex-col items-start justify-between">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
        </div>
        <div className="absolute top-[511px] left-[1390px] w-[103px] h-[103px] flex flex-col items-start justify-between">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
        </div>
        <div className="absolute top-[145px] left-[46px] w-[109px] h-[49px] flex flex-col items-start justify-between">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch hidden flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            <div className="relative rounded-[50%] bg-gray w-1 h-1" />
          </div>
        </div>
        <div className="absolute top-[965px] left-[0px] box-border w-[155px] h-[155px] border-[1px] border-solid border-gray" />
        <div className="absolute top-[0px] left-[1375px] box-border w-[155px] h-[155px] border-[1px] border-solid border-gray" />
      </div>
      <div className="absolute bottom-[0px] left-[calc(50%_-_683px)] flex flex-col pt-0 px-0 pb-8 items-center justify-start gap-[32px] text-white">
        <div className="relative box-border w-[1367px] h-px border-t-[1px] border-solid border-gray" />
        <div className="flex flex-col items-center justify-start gap-[48px]">
          <div className="w-[1025px] flex flex-row items-start justify-between">
            <div className="flex flex-col items-start justify-start gap-[16px]">
              <div className="self-stretch flex flex-row items-center justify-start gap-[24px]">
                <div className="flex flex-row items-center justify-start gap-[9px]">
                  <div className="relative w-4 h-4">
                    <div className="relative bg-white w-4 h-4">
                      <div className="absolute h-1/4 w-3/12 top-[0%] right-[25%] bottom-[75%] left-[50%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[50%] right-[0%] bottom-[25%] left-[75%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[25%] right-[0%] bottom-[50%] left-[75%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[0%] right-[0%] bottom-[75%] left-[75%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[50%] right-[25%] bottom-[25%] left-[50%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[25%] right-[50%] bottom-[50%] left-[25%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[25%] right-[75%] bottom-[50%] left-[0%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[50%] right-[75%] bottom-[25%] left-[0%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[75%] right-[75%] bottom-[0%] left-[0%] bg-white" />
                      <div className="absolute h-1/4 w-3/12 top-[75%] right-[50%] bottom-[0%] left-[25%] bg-white" />
                    </div>
                  </div>
                  <div className="relative font-medium">Elias</div>
                </div>
                <a
                  className="relative text-gray [text-decoration:none]"
                  href="mailto:elias@elias-dev.ml"
                  target="_blank"
                >
                  elias@elias-dev.ml
                </a>
              </div>
              <div className="relative">
                Web designer and front-end developer
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[12px] text-5xl">
              <div className="relative font-medium">Media</div>
              <img className="relative w-28 h-8" alt="" src="/group-221.svg" />
            </div>
          </div>
          <div className="relative text-gray inline-block w-[298px]">
            © Copyright 2022. Made by Elias
          </div>
        </div>
      </div>
      <div className="absolute top-[114px] left-[171px] w-[174px] h-[77px] flex flex-col items-start justify-start gap-[14px] text-13xl">
        <div className="flex flex-row items-start justify-start">
          <div className="relative font-semibold">/</div>
          <div className="relative font-semibold text-white">about-me</div>
        </div>
        <div className="relative text-base text-white">Who am i?</div>
      </div>
      <div className="absolute top-[199px] left-[176px] w-[1028px] h-[508px] flex flex-row items-center justify-end gap-[170px] text-gray">
        <div className="relative leading-[25px] inline-block w-[515px] shrink-0">
          <p className="m-0">Hello, i’m Elias!</p>
          <p className="m-0">&nbsp;</p>
          <p className="m-0">{`I’m a self-taught front-end developer based in Kyiv, Ukraine. I can develop responsive websites from scratch and raise them into modern user-friendly web experiences. `}</p>
          <p className="m-0">&nbsp;</p>
          <p className="m-0">
            Transforming my creativity and knowledge into a websites has been my
            passion for over a year. I have been helping various clients to
            establish their presence online. I always strive to learn about the
            newest technologies and frameworks.
          </p>
        </div>
        <div className="relative w-[343px] h-[508px]">
          <img
            className="absolute top-[0px] left-[4px] w-[339px] h-[507px] object-cover"
            alt=""
            src="/image@2x.png"
          />
          <div className="absolute top-[59px] left-[0px] w-[84px] h-[84px] flex flex-col items-start justify-between">
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            </div>
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            </div>
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            </div>
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            </div>
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            </div>
          </div>
          <div className="absolute top-[279px] left-[223px] w-[104px] h-14 flex flex-col items-start justify-between">
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            </div>
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            </div>
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            </div>
            <div className="self-stretch hidden flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            </div>
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            </div>
          </div>
          <img
            className="absolute top-[507px] left-[48px] w-[271px] h-px"
            alt=""
            src="/line-7-stroke2.svg"
          />
        </div>
      </div>
      <div className="absolute top-[819px] left-[171px] w-[1024px] h-[251px] flex flex-col items-start justify-start gap-[48px] text-13xl">
        <div className="flex flex-row items-start justify-start">
          <div className="relative font-medium">#</div>
          <div className="relative font-medium text-white">skills</div>
        </div>
        <div className="w-[1024px] flex flex-row items-start justify-start gap-[16px] text-base text-white">
          <div className="flex-1 flex flex-col py-2 px-0 items-start justify-start gap-[8px] border-[1px] border-solid border-gray">
            <div className="self-stretch flex flex-row py-0 px-2 items-start justify-start">
              <div className="relative font-semibold">Languages</div>
            </div>
            <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gray" />
            <div className="flex flex-col py-0 px-2 items-start justify-start gap-[8px] text-gray">
              <div className="flex flex-row items-start justify-start gap-[8px]">
                <div className="relative">TypeScript</div>
                <div className="relative">Lua</div>
                <div className="relative hidden">Lua</div>
                <div className="relative hidden">Lua</div>
              </div>
              <div className="flex flex-row items-start justify-start gap-[8px]">
                <div className="relative">Python</div>
                <div className="relative">JavaScript</div>
                <div className="relative hidden">JavaScript</div>
              </div>
              <div className="hidden flex-row items-start justify-start gap-[11px]">
                <div className="relative">Python</div>
                <div className="relative">JavaScript</div>
              </div>
              <div className="hidden flex-row items-start justify-start gap-[11px]">
                <div className="relative">Python</div>
                <div className="relative">JavaScript</div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col py-2 px-0 items-start justify-start gap-[8px] border-[1px] border-solid border-gray">
            <div className="self-stretch flex flex-row py-0 px-2 items-start justify-start">
              <div className="relative font-semibold">Other</div>
            </div>
            <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gray" />
            <div className="flex flex-col py-0 px-2 items-start justify-start gap-[8px] text-gray">
              <div className="flex flex-row items-start justify-start gap-[8px]">
                <div className="relative">HTML</div>
                <div className="relative">CSS</div>
                <div className="relative">EJS</div>
                <div className="relative">SCSS</div>
              </div>
              <div className="flex flex-row items-start justify-start gap-[8px]">
                <div className="relative">REST</div>
                <div className="relative">Jinja</div>
                <div className="relative hidden">JavaScript</div>
              </div>
              <div className="hidden flex-row items-start justify-start gap-[11px]">
                <div className="relative">Jinja</div>
                <div className="relative hidden">Express.js</div>
              </div>
              <div className="hidden flex-row items-start justify-start gap-[11px]">
                <div className="relative">Python</div>
                <div className="relative">JavaScript</div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col py-2 px-0 items-start justify-start gap-[8px] border-[1px] border-solid border-gray">
            <div className="self-stretch flex flex-row py-0 px-2 items-start justify-start">
              <div className="relative font-semibold">Tools</div>
            </div>
            <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gray" />
            <div className="flex flex-col py-0 px-2 items-start justify-start gap-[8px] text-gray">
              <div className="flex flex-row items-start justify-start gap-[8px]">
                <div className="relative">VSCode</div>
                <div className="relative">Neovim</div>
                <div className="relative hidden">Lua</div>
                <div className="relative">Linux</div>
              </div>
              <div className="flex flex-row items-start justify-start gap-[8px]">
                <div className="relative">Figma</div>
                <div className="relative">XFCE</div>
                <div className="relative">Arch</div>
              </div>
              <div className="flex flex-row items-start justify-start gap-[11px]">
                <div className="relative">Git</div>
                <div className="relative">Font Awesome</div>
              </div>
              <div className="flex flex-row items-start justify-start gap-[11px]">
                <div className="relative">KDE</div>
                <div className="relative">fish</div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col py-2 px-0 items-start justify-start gap-[8px] border-[1px] border-solid border-gray">
            <div className="self-stretch flex flex-row py-0 px-2 items-start justify-start">
              <div className="relative font-semibold">Databases</div>
            </div>
            <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gray" />
            <div className="flex flex-col py-0 px-2 items-start justify-start gap-[8px] text-gray">
              <div className="flex flex-row items-start justify-start gap-[8px]">
                <div className="relative">SQLite</div>
                <div className="relative">PostgreSQL</div>
                <div className="relative hidden">Lua</div>
                <div className="relative hidden">Lua</div>
              </div>
              <div className="flex flex-row items-start justify-start gap-[8px]">
                <div className="relative">Mongo</div>
                <div className="relative hidden">avaScript</div>
                <div className="relative hidden">JavaScript</div>
              </div>
              <div className="hidden flex-row items-start justify-start gap-[11px]">
                <div className="relative">Python</div>
                <div className="relative">JavaScript</div>
              </div>
              <div className="hidden flex-row items-start justify-start gap-[11px]">
                <div className="relative">Python</div>
                <div className="relative">JavaScript</div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col py-2 px-0 items-start justify-start gap-[8px] border-[1px] border-solid border-gray">
            <div className="self-stretch flex flex-row py-0 px-2 items-start justify-start">
              <div className="relative font-semibold">Frameworks</div>
            </div>
            <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gray" />
            <div className="flex flex-col py-0 px-2 items-start justify-start gap-[8px] text-gray">
              <div className="flex flex-row items-start justify-start gap-[8px]">
                <div className="relative">React</div>
                <div className="relative">Vue</div>
                <div className="relative hidden">Lua</div>
                <div className="relative hidden">Lua</div>
              </div>
              <div className="flex flex-row items-start justify-start gap-[8px]">
                <div className="relative">Disnake</div>
                <div className="relative">Discord.js</div>
                <div className="relative hidden">JavaScript</div>
              </div>
              <div className="flex flex-row items-start justify-start gap-[11px]">
                <div className="relative">Flask</div>
                <div className="relative">Express.js</div>
              </div>
              <div className="hidden flex-row items-start justify-start gap-[11px]">
                <div className="relative">Python</div>
                <div className="relative">JavaScript</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[1153px] left-[171px] w-[939px] h-[264px] text-13xl">
        <div className="absolute top-[0px] left-[0px] flex flex-row items-start justify-start">
          <div className="relative font-medium">#</div>
          <div className="relative font-medium text-white">my-fun-facts</div>
        </div>
        <div className="absolute top-[68px] left-[0px] flex flex-col items-start justify-start gap-[16px] text-base text-gray">
          <div className="flex flex-row items-start justify-start gap-[16px]">
            <div className="flex flex-row p-2 items-start justify-start border-[1px] border-solid border-gray">
              <div className="relative">I like winter more than summer</div>
            </div>
            <div className="flex flex-row p-2 items-start justify-start border-[1px] border-solid border-gray">
              <div className="relative">I often bike with my friends</div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start gap-[16px]">
            <div className="flex flex-row p-2 items-start justify-start border-[1px] border-solid border-gray">
              <div className="relative">
                <span>{`I like `}</span>
                <span className="text-white">pizza</span>
                <span>{` and `}</span>
                <span className="text-white">pasta</span>
              </div>
            </div>
            <div className="flex flex-row p-2 items-start justify-start border-[1px] border-solid border-gray">
              <div className="relative">
                <span>{`I was in `}</span>
                <span className="text-white">Egypt</span>
                <span>{`, `}</span>
                <span className="text-white">Poland</span>
                <span>{` and `}</span>
                <span className="text-white">Turkey</span>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start gap-[17px]">
            <div className="flex flex-row p-2 items-start justify-start border-[1px] border-solid border-gray">
              <div className="relative">
                <span>{`My favorite movie is `}</span>
                <span className="text-white">The Green Mile</span>
              </div>
            </div>
            <div className="flex flex-row p-2 items-start justify-start border-[1px] border-solid border-gray">
              <div className="relative">I am still in school</div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start">
            <div className="flex flex-row p-2 items-start justify-start border-[1px] border-solid border-gray">
              <div className="relative">I don’t have any siblings</div>
            </div>
          </div>
        </div>
        <div className="absolute top-[68px] left-[761px] w-[178px] h-[169px]">
          <div className="absolute top-[0px] left-[0px] flex flex-col items-start justify-between">
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1 hidden" />
            </div>
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1 hidden" />
            </div>
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1 hidden" />
            </div>
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1 hidden" />
            </div>
            <div className="self-stretch hidden flex-row items-start justify-between">
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
              <div className="relative rounded-[50%] bg-gray w-1 h-1" />
            </div>
          </div>
          <img
            className="absolute top-[56px] left-[calc(50%_-_25px)] w-[114px] h-[114px]"
            alt=""
            src="/logo3.svg"
          />
        </div>
      </div>
      <div className="absolute top-[0px] left-[171px] bg-background w-[1024px] flex flex-row pt-8 px-0 pb-2 box-border items-end justify-between text-white">
        <div
          className="flex flex-row items-center justify-start gap-[8px] cursor-pointer"
          onClick={onFrameContainer92Click}
        >
          <div className="relative w-4 h-4">
            <div className="relative bg-white w-4 h-4">
              <div className="absolute h-1/4 w-3/12 top-[0%] right-[25%] bottom-[75%] left-[50%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[50%] right-[0%] bottom-[25%] left-[75%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[25%] right-[0%] bottom-[50%] left-[75%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[0%] right-[0%] bottom-[75%] left-[75%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[50%] right-[25%] bottom-[25%] left-[50%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[25%] right-[50%] bottom-[50%] left-[25%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[25%] right-[75%] bottom-[50%] left-[0%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[50%] right-[75%] bottom-[25%] left-[0%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[75%] right-[75%] bottom-[0%] left-[0%] bg-white" />
              <div className="absolute h-1/4 w-3/12 top-[75%] right-[50%] bottom-[0%] left-[25%] bg-white" />
            </div>
          </div>
          <b className="relative">Elias</b>
        </div>
        <div className="flex flex-row items-start justify-start gap-[32px] text-primary">
          <div
            className="flex flex-row items-start justify-start cursor-pointer"
            onClick={onHeaderLinkContainerClick}
          >
            <div className="relative font-medium">#</div>
            <div className="relative font-medium text-white">home</div>
          </div>
          <div className="flex flex-row items-start justify-start">
            <div className="relative">#</div>
            <div className="relative text-gray">works</div>
          </div>
          <div className="flex flex-row items-start justify-start">
            <div className="relative">#</div>
            <div className="relative text-gray">about-me</div>
          </div>
          <div className="flex flex-row items-start justify-start">
            <div className="relative">#</div>
            <div className="relative text-gray">contacts</div>
          </div>
          <div className="flex flex-row items-center justify-start relative gap-[4px] text-gray">
            <div className="relative font-semibold z-[0]">EN</div>
            <img
              className="relative w-2.5 h-[6.41px] z-[1]"
              alt=""
              src="/group-58.svg"
            />
            <div className="my-0 mx-[!important] absolute top-[25px] left-[0px] bg-background box-border h-px overflow-hidden flex flex-col p-2 items-start justify-start gap-[8px] opacity-[0] z-[2] border-[1px] border-solid border-gray">
              <div className="relative">RU</div>
              <div className="relative">UA</div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[0px] left-[17px] bg-background flex flex-col items-center justify-start gap-[8px]">
        <img
          className="relative w-px h-[191px]"
          alt=""
          src="/line-10-stroke1.svg"
        />
        <div className="flex flex-col items-center justify-start gap-[8px]">
          <img className="relative w-8 h-8" alt="" src="/github.svg" />
          <img className="relative w-8 h-8" alt="" src="/dribble.svg" />
          <img className="relative w-8 h-8" alt="" src="/figma.svg" />
        </div>
      </div>
    </div>
  );
};

export default About;
