const HeroSection = () => {
    return (
        <div className="pt-24">
            <div className="flex flex-row justify-between">
                <div className="flex flex-col w-full mb-20 sm:basis-1/2">
                    <div className="text-13xl font-semibold inline-block mb-6">Manuel is a <span className="text-primary"><br/>full-stack<br/> web developer</span></div>
                    <div className="text-gray inline-block mb-6">Transforming your digital vision into a seamless reality - Empowering businesses through innovative web development solutions.</div>
                    <a href='#contact-section' className="no-underline text-primary px-2 py-2 border-[1px] border-solid border-primary font-medium text-center">Contact me!!</a>
                </div>
                <img className="hidden rounded-full w-80 h-80" src="head-shot.png" alt="Hero Image" />
            </div>
            <div className="hidden bg-background md:flex flex-row items-start justify-start gap-[10px] text-3xl mb-20">
                <div className="relative font-medium z-[0]">I would love to change the world, but they won't give me the source code.</div>
                <div className="my-0 mx-[!important] flex flex-row p-4 items-start justify-start z-[2]">
                    <div className="relative text-gray">- Unknown</div>
                </div>
            </div>
        </div>
    )
};
export default HeroSection;