import SectionHeader from "./SectionHeader";

const AboutSection = () => {
    return (
        <div className="mb-40 flex flex-col">
          <SectionHeader title='about-me' />
          <div className="flex">
            <div className="flex flex-col items-start justify-start gap-[27px]">
              <div className="relative leading-[26px] inline-block">
                <p className="m-0 mb-4">Hello, I’m Manuel!</p>
                <p className="m-0">An experienced self-taught full stack web developer with over 5 years of expertise in the financial services industry. Proficient in independent problem-solving and thriving within diverse team environments to deliver top-notch products. A second career software developer with a well-rounded perspective, bringing valuable insights into business processes and systems beyond engineering boundaries.</p>
              </div>
              <div className="flex flex-row py-2 px-4 items-start justify-start text-white border-[1px] border-solid border-primary">Read more -</div>
            </div>
          </div>
      </div>
    )
};

export default AboutSection;