import ContactDetails from "./ContactDetails";
import ContactForm from "./ContactForm";
import SectionHeader from "./SectionHeader";

const ContactSection = () => {
  return (
    <div className="text-primary mb-40" id='contact-section'>
      <SectionHeader title="lets-talk" />
      <div className="font-medium text-gray inline-block mb-8">What kind of plans do you have for your digital product? Let's work through it together.</div>
      <div className="flex flex-col p-4 px-0 items-start justify-between gap-[16px] text-white">
        <ContactForm />
        <ContactDetails isNavDisplay={true}/>
      </div>
    </div>
  );
};

export default ContactSection;
