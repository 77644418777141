const ContactDetails = ({isNavDisplay}) => {
    const displaySizeStyle = isNavDisplay ? 'w-6 h-6' : 'w-8 h-8';
    const iconPaddingStyle = isNavDisplay ? '' : 'pb-5';

    return (
        <div className={`w-full ${isNavDisplay ? 'flex justify-between align-middle' : ''}`}>
            <div className={`${iconPaddingStyle} flex basis-1/12 md:basis-1/3 md:justify-end`}>
                <img src="/email.svg" alt="Email" className={`${displaySizeStyle} pr-3`} />
                <span className="hidden md:block">manuelsalcido2012@gmail.com</span>
            </div>
            <div className={`${iconPaddingStyle} flex basis-1/12 md:basis-1/3 md:justify-center`}>
                <img src="/phone.svg" alt="Email" className={`${displaySizeStyle} pr-3`} />
                <span className="hidden md:block">915-740-7347</span>
            </div>
            <div className='flex justify-between basis-2/3 md:basis-1/4'>
                <img src="/facebook.svg" alt="Facebook" className={displaySizeStyle} />
                <img src="/instagram.svg" alt="Instagram" className={displaySizeStyle} />
                <img src="/twitter.svg" alt="Twitter" className={displaySizeStyle} />
                <img src="/linkedin.svg" alt="LinkedIn" className={displaySizeStyle} />
            </div>
        </div>
    )
};
export default ContactDetails;
