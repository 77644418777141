import ProjectCard from "./ProjectCard";
import SectionHeader from "./SectionHeader";

const portfolioData = [
  {
      thumbnail: '/rectangle-22@2x.png',
      imgs: [],
      stack: ['HTML', 'SCSS', 'Python', 'Flask'],
      title: 'ChertNodes',
      briefDescription: 'Minecraft servers hosting',
      fullDescription: '',
      url: 'https://chertnodes.com',
  },
  {
      thumbnail: '/rectangle-221@2x.png',
      imgs: [],
      stack: ['React', 'Express', 'Discord', 'Node'],
      title: 'ProtectX',
      briefDescription: 'Discord Anti-Crash Bot',
      fullDescription: '',
      url: 'https://protectx.com',
  },
  {
      thumbnail: '/rectangle-222@2x.png',
      imgs: [],
      stack: ['HTML', 'SCSS', 'Python', 'Flask'],
      title: 'ChertNodes',
      briefDescription: 'Minecraft servers hosting',
      fullDescription: '',
      url: 'https://chertnodes.com',
  }
]

const ProjectSection = () => {
    return (
        <div className="flex flex-col mb-40">
          <SectionHeader title='projects'/>
          <div className="flex flex-col justify-start gap-[64px] text-base text-gray w-full">
            {portfolioData.map(cardData => <ProjectCard cardData={cardData} />)}
          </div>
      </div>
    )
}

export default ProjectSection;