import ContactSection from "../components/ContactSection";
import AboutSection from "../components/AboutSection";
import SkillSection from "../components/SkillSection";
import ProjectSection from "../components/ProjectSection";
import HeroSection from "../components/HeroSection";

const Home = () => {
  return (
    <div className="max-w-full">
      <HeroSection />
      <ProjectSection />
      <SkillSection />
      <ContactSection />
      <AboutSection />
    </div>
  );
};

export default Home;
