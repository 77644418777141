import ContactDetails from "./ContactDetails";

const Footer = () => {
    return (
      <div className="flex flex-col justify-start p-4">
        <div className="flex flex-row items-start justify-between mb-8">              
          <div className="flex flex-col items-left justify-start gap-[4px]">
            <div className="relative font-medium">Manuel Salcido</div>
            <div className="relative text-primary">Full-stack Web Developer</div>
            <a
              className="relative text-white [text-decoration:none]"
              href="mailto:elias@elias-dev.ml"
              target="_blank"
            >
              manuelsalcido2012@gmail.com
            </a>
            <div className="relative font-medium">915-740-7347</div>
          </div>
        </div>
        <div className="mb-8"><ContactDetails isNavDisplay={true} /></div>
        <div className="flex text-sm justify-start mb-3">
          <div className="basis-1/2">Terms & Conditions</div>
          <div>Privacy Policy</div>
        </div>
        <div className="relative text-gray inline-block text-sm">© Copyright 2023 Made by Manuel Salcido</div>
      </div>
    )
}

export default Footer;