const SectionHeader = ({ title, hasLink }) => {
    return (
        <div className="flex flex-row items-baseline justify-between max-w-full mb-10 text-13xl text-primary">
          <div className="flex flex-row items-baseline gap-[16px]">
            <div className="relative font-medium">#<span className="text-white">{title}</span></div>
            <img
              className="flex-1 relative max-w-full overflow-hidden h-px"
              alt=""
              src="/line-7-stroke4.svg"
            />
          </div>
          {hasLink && <div className="relative text-base font-medium text-white cursor-pointer">{`View all ~~>`}</div>}
        </div>
    );
};

export default SectionHeader;